<template>
  <div>

    
    <h2>ARTICLE 1 - Scope</h2>

    <p>These General Conditions of Sale (known as "GTC") apply, without restriction or reservation to all sales
      concluded by the Seller to non-professional buyers ("The Customers or the Customer"), wishing to acquire the
      products. offered for sale ("The Products") by the Seller on the site https://practical-go-lessons.com. The
      Products offered for sale on the site are as follows:</p>

    <p>The book "Practical Go Lessons" written by Maximilien Andile</p>
    <p>The main characteristics of the Products and in particular the specifications, illustrations and indications of
      dimensions or capacity of the Products, are presented on the site https://practical-go-lessons.com which the
      customer is required to read before ordering.</p>
    <p>The choice and purchase of a Product are the sole responsibility of the Customer.</p>
    <p>Product offers are subject to the limit of available stocks, as specified when placing the order.</p>
    <p>These terms are accessible at any time on the site https://practical-go-lessons.com and will prevail over any
      other document.</p>
    <p>The Customer declares to have read these GTC and to have accepted them by checking the box provided for this
      purpose before implementing the online ordering procedure of the site https://practical-go-lessons.com.</p>
    <p>Unless proven otherwise, the data recorded in the Seller's computer system constitutes proof of all transactions
      concluded with the Customer.</p>

    <p>The Seller's contact details are as follows:</p>


    <p>MAXIMILIEN ANDILE CONSULTING, SARL</p>


    <p>Share capital of 1000 euros</p>


    <p>Registered with the RCS of Paris, under number Paris B 877 989 723</p>

    <p>233 Rue Du Faubourg Saint Honoré</p>

    <p>e-mail: orders@practical-go-lessons.com</p>

    <p>VAT number FR44877989723</p>

    <p>The Products presented on the site https://practical-go-lessons.com are offered for sale for the following
      territories:</p>
    <p>Afghanistan, Albania, Algeria, American Samoa, Andorra, Angola, Antarctica, Argentina, Armenia, Australia,
      Austria, Azerbaijan, Bahrain, Bangladesh, Belarus, Belgium, Benin, Bolivia, Bosnia and Herzegovina, Brazil,
      British Indian Ocean Territory, British Virgin Islands, Brunei, Bulgaria, Burkina Faso, Burundi, Cambodia,
      Cameroon, Canada, Central African Republic, Chad, Chile, China, Christmas Island, Cocos (Keeling) Islands,
      Colombia, Comoros, Cook Islands, Costa Rica, Croatia, Curaçao, Cyprus, Czech Republic, DR Congo, Denmark,
      Djibouti, Egypt, El Salvador, Equatorial Guinea, Eritrea, Estonia, Ethiopia, Falkland Islands, Faroe Islands,
      Fiji, Finland, France, French Guiana, French Polynesia, French Southern and Antarctic Lands, Gabon, Georgia,
      Germany, Ghana, Gibraltar, Greece, Greenland, Guadeloupe, Guam, Guatemala, Guernsey, Guinea, Honduras, Hong Kong,
      Hungary, Iceland, India, Indonesia, Iran, Iraq, Ireland, Isle of Man, Israel, Italy, Ivory Coast, Japan, Jersey,
      Jordan, Kazakhstan, Kenya, Kiribati, Kuwait, Kyrgyzstan, Latvia, Lebanon, Lesotho, Liechtenstein, Lithuania,
      Luxembourg, Macau, Macedonia, Madagascar, Malaysia, Maldives, Mali, Malta, Marshall Islands, Martinique,
      Mauritius, Mayotte, Mexico, Micronesia, Moldova, Monaco, Montenegro, Morocco, Namibia, Nauru, Nepal, Netherlands,
      New Caledonia, New Zealand, Niger, Nigeria, Norfolk Island, North Korea, Northern Mariana Islands, Norway, Oman,
      Pakistan, Palau, Panama, Papua New Guinea, Philippines, Pitcairn Islands, Poland, Portugal, Qatar, Republic of the
      Congo, Romania, Russia, Rwanda, Réunion, Saint Barthélemy, Saint Helena, Saint Martin, Saint Pierre and Miquelon,
      Saint Vincent and the Grenadines, Samoa, San Marino, Saudi Arabia, Senegal, Serbia, Singapore, Slovakia, Slovenia,
      Solomon Islands, South Africa, South Georgia, South Korea, South Sudan, Spain, Sri Lanka, Sudan, Suriname,
      Swaziland, Sweden, Switzerland, São Tomé and Príncipe, Taiwan, Tajikistan, Tanzania, Thailand, Timor-Leste, Togo,
      Tonga, Trinidad and Tobago, Tunisia, Turkey, Turkmenistan, Tuvalu, Uganda, Ukraine, United Arab Emirates, United
      Kingdom, United States, United States Virgin Islands, Vanuatu, Vatican City, Vietnam, Wallis and Futuna, Zambia,
      Zimbabwe and Åland Islands.</p>

    <p>In the event of an order to a country other than mainland France, the Customer is the importer of the Product(s)
      concerned.</p>
    <p>For all Products shipped outside the European Union and DOM-TOM, the price will be calculated excluding tax
      automatically on the invoice.</p>
    <p>Customs duties or other local taxes or import duties or state taxes may be payable. They will be the
      responsibility and are the sole responsibility of the Customer.</p>

    
    <h2>ARTICLE 2 - Price</h2>

    <p>The Products are supplied at the current prices appearing on the site https://practical-go-lessons.com, during
      the registration of the order by the Seller.</p>


    <p>The prices are expressed in Euros VAT included.</p>


    <p>The prices take into account any reductions that would be granted by the Seller on the site
      https://practical-go-lessons.com.</p>


    <p>These prices are firm and not subject to revision during their period of validity, but the Seller reserves the
      right, outside the period of validity, to modify the prices at any time.</p>


    <p>The prices do not include the costs of processing, shipping, transport and delivery, which are invoiced in
      addition, under the conditions indicated on the site and calculated before placing the order.</p>


    <p>The payment requested from the Customer corresponds to the total amount of the purchase, including these
      costs.</p>


    <p>An invoice is established by the Seller and given to the Customer upon delivery of the Products ordered.</p>


    
    <h2>3 - Orders</h2>
    <p>It is up to the Customer to select on the site https://practical-go-lessons.com the Products he wishes to order,
      according to the following modalities:</p>


    <p>The Customer chooses a Product that he puts in his basket, a Product that he can delete or modify before
      confirming his order and accepting these general conditions of sale. He will then enter his details or connect to
      his space and choose the delivery method. After validation of the information, the order will be considered final
      and will require payment from the Customer according to the terms provided.</p>


    <p>Product offers are valid as long as they are visible on the site, within the limits of available stocks.</p>


    <p>The sale will only be considered valid after full payment of the price. It is the Customer's responsibility to
      verify the accuracy of the order and to report any errors immediately.</p>


    <p>Any order placed on the site https://practical-go-lessons.com constitutes the formation of a contract concluded
      at a distance between the Customer and the Seller.</p>


    <p>The Seller reserves the right to cancel or refuse any order from a Customer with whom there is a dispute relating
      to the payment of a previous order.</p>


    <p>The Customer will be able to follow the progress of his order on the site.</p>


    
    <h2>4 - Payment terms</h2>
    <p>The price is paid by secure payment, according to the following terms:</p>

    <p>payment by credit card</p>
    <p>or payment by bank transfer to the Seller's bank account (the details of which are communicated to the Customer
      when placing the order)</p>
    <p>The price is payable in cash by the Customer, in full on the day the order is placed.</p>


    <p>However, the Customer may, when this possibility is indicated on the site https://practical-go-lessons.com, pay
      according to the following conditions and schedule:</p>


    <p>In addition, the Seller reserves the right, in the event of non-compliance with the terms of payment appearing
      above, to suspend or cancel the delivery of orders in progress made by the Customer.</p>

    <p>Payment data is exchanged in encrypted mode using the protocol defined by the approved payment service provider
      for banking transactions carried out on the site https://practical-go-lessons.com.</p>

    <p>Payments made by the Customer will only be considered final after actual receipt by the Seller of the sums
      due.</p>

    <p>The Seller will not be required to deliver the Products ordered by the Customer if the latter does not pay him
      the full price under the conditions indicated above.</p>

    
    <h2>5 - Deliveries</h2>


    <p>The Products ordered by the Customer will be delivered in mainland France or in the following zone (s):</p>


    <p>Afghanistan, Albania, Algeria, American Samoa, Andorra, Angola, Antarctica, Argentina, Armenia, Australia,
      Austria, Azerbaijan, Bahrain, Bangladesh, Belarus, Belgium, Benin, Bolivia, Bosnia and Herzegovina, Brazil,
      British Indian Ocean Territory, British Virgin Islands, Brunei, Bulgaria, Burkina Faso, Burundi, Cambodia,
      Cameroon, Canada, Central African Republic, Chad, Chile, China, Christmas Island, Cocos (Keeling) Islands,
      Colombia, Comoros, Cook Islands, Costa Rica, Croatia, Curaçao, Cyprus, Czech Republic, DR Congo, Denmark,
      Djibouti, Egypt, El Salvador, Equatorial Guinea, Eritrea, Estonia, Ethiopia, Falkland Islands, Faroe Islands,
      Fiji, Finland, France, French Guiana, French Polynesia, French Southern and Antarctic Lands, Gabon, Georgia,
      Germany, Ghana, Gibraltar, Greece, Greenland, Guadeloupe, Guam, Guatemala, Guernsey, Guinea, Honduras, Hong Kong,
      Hungary, Iceland, India, Indonesia, Iran, Iraq, Ireland, Isle of Man, Israel, Italy, Ivory Coast, Japan, Jersey,
      Jordan, Kazakhstan, Kenya, Kiribati, Kuwait, Kyrgyzstan, Latvia, Lebanon, Lesotho, Liechtenstein, Lithuania,
      Luxembourg, Macau, Macedonia, Madagascar, Malaysia, Maldives, Mali, Malta, Marshall Islands, Martinique,
      Mauritius, Mayotte, Mexico, Micronesia, Moldova, Monaco, Montenegro, Morocco, Namibia, Nauru, Nepal, Netherlands,
      New Caledonia, New Zealand, Niger, Nigeria, Norfolk Island, North Korea, Northern Mariana Islands, Norway, Oman,
      Pakistan, Palau, Panama, Papua New Guinea, Philippines, Pitcairn Islands, Poland, Portugal, Qatar, Republic of the
      Congo, Romania, Russia, Rwanda, Réunion, Saint Barthélemy, Saint Helena, Saint Martin, Saint Pierre and Miquelon,
      Saint Vincent and the Grenadines, Samoa, San Marino, Saudi Arabia, Senegal, Serbia, Singapore, Slovakia, Slovenia,
      Solomon Islands, South Africa, South Georgia, South Korea, South Sudan, Spain, Sri Lanka, Sudan, Suriname,
      Swaziland, Sweden, Switzerland, São Tomé and Príncipe, Taiwan, Tajikistan, Tanzania, Thailand, Timor-Leste, Togo,
      Tonga, Trinidad and Tobago, Tunisia, Turkey, Turkmenistan, Tuvalu, Uganda, Ukraine, United Arab Emirates, United
      Kingdom, United States, United States Virgin Islands, Vanuatu, Vatican City, Vietnam, Wallis and Futuna, Zambia,
      Zimbabwe and Åland Islands.</p>


    <p>Deliveries are made within a period of Delivery times (after making the order and sending it to the Carrier) are
      generally two working days. This period may be extended depending on the Carrier. at the address indicated by the
      Customer when ordering on the site.</p>


    <p>Delivery consists of the transfer to the Customer of physical possession or control of the Product. Except in
      special cases or unavailability of one or more Products, the Products ordered will be delivered in one go.</p>


    <p>The Seller undertakes to make its best efforts to deliver the products ordered by the Customer within the
      deadlines specified above.</p>


    <p>If the Products ordered have not been delivered within 10 days after the indicative delivery date, for any reason
      other than force majeure or the Customer's fault, the sale may be canceled at the Customer's written request
      within conditions provided for in Articles L 216-2, L 216-3 and L241-4 of the Consumer Code. The sums paid by the
      Customer will then be returned to him at the latest within fourteen days following the date of termination of the
      contract, to the exclusion of any compensation or withholding.</p>


    <p>Deliveries are made by an independent carrier, to the address mentioned by the Customer when ordering and which
      the carrier can easily access.</p>


    <p>When the Customer is responsible for calling on a carrier that he himself chooses, delivery is deemed to have
      been made as soon as the Products ordered by the Seller are handed over to the carrier who has accepted them
      without reservations. The Customer therefore acknowledges that it is the carrier who is responsible for making the
      delivery and has no recourse in warranty against the Seller in the event of failure to deliver the goods
      transported.</p>


    <p>In the event of a specific request from the Customer concerning the conditions of packaging or transport of the
      products ordered, duly accepted in writing by the Seller, the related costs will be the subject of a specific
      additional invoicing, on an estimate previously accepted in writing by the customer.</p>


    <p>The Customer is required to check the condition of the products delivered. He has 30 days from receipt of the
      package. from the delivery to formulate complaints by The complaint can be made by mail (to the address
      "Maximilien Andile Consulting 233 rue du faubourg saint honoré 75008 Paris" or by email to the following address
      "orders@practical-go-lessons.com "., accompanied by all the relevant supporting documents (photos in particular).
      After this period and failing to comply with these formalities, the Products will be deemed to comply and free
      from any apparent defect and no complaint can be validly accepted by the Seller .</p>


    <p>The Seller will reimburse or replace as soon as possible and at its expense, the Products delivered for which the
      lack of conformity or the apparent or hidden defects have been duly proved by the Customer, under the conditions
      provided for in Articles L 217-4 et seq. Consumer Code and those provided for in these GTC.</p>


    <p>The transfer of the risks of loss and of deterioration relating thereto, will only be carried out when the
      Customer takes physical possession of the Products. The Products therefore travel at the risk and peril of the
      Seller, except when the Customer himself has chosen the carrier. As such, the risks are transferred when the goods
      are handed over to the carrier.</p>


    
    <h2>6 - Transfer of ownership</h2>


    <p>The transfer of ownership of the Products from the Seller to the Customer will only be carried out after full
      payment of the price by the latter, regardless of the date of delivery of said Products.</p>


    
    <h2>7 - Right of withdrawal</h2>


    <p>According to the terms of article L221-18 of the Consumer Code “The consumer has a period of fourteen days to
      exercise his right of withdrawal from a contract concluded at a distance, following a direct telephone call or
      outside establishment, without having to justify its decision or bear any costs other than those provided for in
      Articles L. 221-23 to L. 221-25.</p>


    <p>The period mentioned in the first paragraph runs from the day:</p>


    <p>1 ° The conclusion of the contract, for service provision contracts and those mentioned in Article L. 221-4;</p>


    <p>2 ° Receipt of the goods by the consumer or a third party, other than the carrier, designated by him, for
      contracts for the sale of goods. For contracts concluded off-premises, the consumer may exercise his right of
      withdrawal from the conclusion of the contract.</p>


    <p>In the case of an order for several goods delivered separately or in the case of an order for a good made up of
      lots or multiple pieces whose delivery is spread over a defined period, the period starts from receipt. of the
      last good or lot or of the last part.</p>


    <p>For contracts providing for the regular delivery of goods during a defined period, the period starts from the
      receipt of the first good. "</p>


    <p>The right of withdrawal can be exercised online, using the attached withdrawal form and also available on the
      site or any other declaration, unambiguous, expressing the desire to withdraw and in particular by mail addressed
      to the Seller at the postal or email address indicated in ARTICLE 1 of the GTC.</p>


    <p>Returns must be made in their original condition and complete (packaging, accessories, instructions, etc.)
      allowing their remarketing in new condition, accompanied by the purchase invoice.</p>


    <p>Damaged, soiled or incomplete Products are not taken back.</p>


    <p>The return costs remain the responsibility of the Customer.</p>


    <p>The exchange (subject to availability) or reimbursement will be made within 14 days of receipt by the Seller of
      the Products returned by the Customer under the conditions provided for in this article.</p>


    
    <h2>8 - Seller's liability - Guarantees</h2>
    <p>The Products supplied by the Seller benefit from:</p>


    <p>the legal guarantee of conformity, for defective, damaged or damaged Products or not corresponding to the
      order,</p>
    <p>the legal guarantee against hidden defects resulting from a material, design or manufacturing defect affecting
      the delivered products and rendering them unfit for use,</p>
    <p>Provisions relating to legal guarantees</p>


    <p>Article L217-4 of the Consumer Code</p>


    <p>“The seller is required to deliver goods in conformity with the contract and is liable for any lack of conformity
      existing at the time of delivery. It also responds to any lack of conformity resulting from the packaging,
      assembly instructions or installation when this has been charged to it by the contract or has been carried out
      under its responsibility. "</p>


    <p>Article L217-5 of the Consumer Code</p>


    <p>"The good is in accordance with the contract:</p>


    <p>1 ° If it is suitable for the use usually expected of a similar good and, if applicable:</p>


    <p>- if it corresponds to the description given by the seller and has the qualities that the latter presented to the
      buyer in the form of a sample or model;</p>


    <p>- if it has the qualities that a buyer can legitimately expect given the public statements made by the seller,
      the producer or his representative, in particular in advertising or labeling;</p>


    <p>2 ° Or if it has the characteristics defined by mutual agreement by the parties or is suitable for any special
      use sought by the buyer, brought to the attention of the seller and which the latter has accepted. "</p>


    <p>Article L217-12 of the Consumer Code</p>


    <p>“The action resulting from the lack of conformity lapses two years after delivery of the goods. "</p>


    <p>Article 1641 of the Civil Code.</p>


    <p>"The seller is bound by the guarantee for hidden defects in the item sold which make it unfit for the use for
      which it is intended, or which reduce this use so much that the buyer has not acquired it, or would have given a
      lower price, if he had known them. "</p>


    <p>Article 1648 paragraph 1 of the Civil Code</p>


    <p>“The action resulting from crippling defects must be brought by the purchaser within two years from the discovery
      of the defect. "</p>


    <p>Article L217-16 of the Consumer Code.</p>


    <p>"When the buyer asks the seller, during the course of the commercial guarantee granted to him during the
      acquisition or repair of movable property, a repair covered by the guarantee, any period of immobilization of at
      least seven days is added to the duration of the guarantee which remained to run. This period runs from the
      buyer's request for intervention or the provision for repair of the property in question, if this provision is
      subsequent to the request for intervention. "</p>


    <p>In order to assert his rights, the Customer must inform the Seller, in writing (email or letter), of the
      non-conformity of the Products or of the existence of hidden defects from their discovery.</p>


    <p>The Seller will reimburse, replace or have repaired the Products or parts under warranty deemed non-compliant or
      defective.</p>


    <p>Shipping costs will be reimbursed on the basis of the invoiced price and return costs will be reimbursed on
      presentation of supporting documents.</p>


    <p>Refunds, replacements or repairs of Products deemed non-compliant or defective will be made as soon as possible
      and at the latest within 30 days of the Seller's finding of the lack of conformity or the hidden defect. This
      reimbursement can be made by bank transfer or check.</p>


    <p>The Seller's liability cannot be engaged in the following cases:</p>


    <p>non-compliance with the legislation of the country in which the products are delivered, which it is up to the
      Customer to verify,</p>
    <p>in the event of misuse, use for professional purposes, negligence or lack of maintenance on the part of the
      Customer, as in the event of normal wear and tear of the Product, accident or force majeure.</p>
    <p>The photographs and graphics presented on the site are not contractual and cannot engage the responsibility of
      the Seller.</p>
    <p>The Seller's guarantee is, in any event, limited to the replacement or reimbursement of non-conforming Products
      or those affected by a defect.</p>


    
    <h2>9 - Personal data</h2>


    <p>The Customer is informed that the collection of his personal data is necessary for the sale of the Products by
      the Seller as well as their transmission to third parties for the purpose of delivering the Products. These
      personal data are collected only for the execution of the sales contract.</p>

    <p>The Customer is also informed that the collection of his personal data is also necessary in view of the following
      purposes:</p>


    <p>- Your information and personal data are used to manage your order on our site.</p>


    <p>- Your information and personal data may be transmitted to our carrier service providers in order to ship your
      order.</p>


    <p>9.1 Collection of personal data</p>


    <p>The personal data that are collected on the site https://practical-go-lessons.com are as follows:</p>


    <p>Ordering Products:</p>


    <p>When ordering Products by the Customer:</p>


    <p>Names, first names, postal address, telephone number and e-mail address.</p>


    <p>Payment</p>


    <p>As part of the payment for the Products offered on the site https://practical-go-lessons.com, it records
      financial data relating to the bank account or credit card of the Customer / user.</p>


    <p>9.2 Recipients of personal data</p>


    <p>Personal data is used by the Seller and its co-contractors for the performance of the contract and to ensure the
      efficiency of the sale and delivery of the Products.</p>


    <p>The category (ies) of co-contractor (s) is (are):</p>


    <p>Transport providers</p>


    <p>Payment institution providers</p>


    <p>9.3 Data controller</p>


    <p>The data controller is the Seller, within the meaning of the Data Protection Act and as of May 25, 2018 of
      Regulation 2016/679 on the protection of personal data.</p>


    <p>9.4 restriction of processing</p>


    <p>Unless the Customer expresses his express consent, his personal data is not used for advertising or marketing
      purposes.</p>


    <p>9.5 Data retention period</p>


    <p>The Seller will keep the data thus collected for a period of 5 years, covering the period of limitation of the
      applicable contractual civil liability.</p>


    <p>9.6 Security and confidentiality</p>


    <p>The Seller implements organizational, technical, software and physical digital security measures to protect
      personal data against alteration, destruction and unauthorized access. However, it should be noted that the
      Internet is not a completely secure environment and the Seller cannot guarantee the security of the transmission
      or storage of information on the Internet.</p>


    <p>9.7 Implementation of the rights of Customers and users</p>


    <p>In application of the regulations applicable to personal data, Customers and users of the site
      https://practical-go-lessons.com have the following rights:</p>


    <p>They can update or delete their data as follows:</p>
    <p>The customer can request the deletion of his personal data either by writing an email to the address
      "orders@practical-go-lessons.com" or by mail to the address "Maximilien Andile Consulting, 233 rue du faubourg
      saint honoré, 75008 Paris". .</p>


    <p>They can delete their account by writing to the email address indicated in article 9.3 "Data controller"</p>
    <p>They can exercise their right of access to know their personal data by writing to the address indicated in
      Article 9.3 "Data Controller"</p>
    <p>If the personal data held by the Seller is inaccurate, they may request the updating of the information of the
      information by writing to the address indicated in Article 9.3 "Data Controller"</p>
    <p>They may request the deletion of their personal data, in accordance with applicable data protection laws, by
      writing to the address indicated in Article 9.3 "Controller"</p>
    <p>They can also request the portability of data held by the Seller to another service provider.</p>
    <p>Finally, they can object to the processing of their data by the Seller</p>
    <p>These rights, as long as they do not conflict with the purpose of the processing, can be exercised by sending a
      request by mail or by e-mail to the Data Controller whose contact details are indicated above.</p>


    <p>The data controller must provide a response within a maximum of one month.</p>


    <p>In the event of refusal to comply with the Customer's request, the latter must be motivated.</p>


    <p>The Customer is informed that in the event of refusal, he can lodge a complaint with the CNIL (3 place de
      Fontenoy, 75007 PARIS) or seize a judicial authority.</p>


    <p>The Customer may be invited to tick a box under which he agrees to receive informative and advertising emails
      from the Seller. He will always have the possibility to withdraw his agreement at any time by contacting the
      Seller (contact details above) or by following the unsubscribe link.</p>


    
    <h2>10 - Intellectual property</h2>
    <p>The content of the https://practical-go-lessons.com site is the property of the Seller and its partners and is
      protected by French and international laws relating to intellectual property.</p>


    <p>Any total or partial reproduction of this content is strictly prohibited and is liable to constitute an offense
      of counterfeiting.</p>


    
    <h2>11 - Applicable law - Language</h2>
    <p>These GTC and the operations resulting from them are governed and subject to French law.</p>


    <p>These T & Cs are written in French. In the event that they are translated into one or more foreign languages,
      only the French text will prevail in the event of a dispute.</p>


    
    <h2>12 - Disputes</h2>
    <p>For any complaint, please contact customer service at the Seller's postal or email address indicated in ARTICLE 1
      of these GTC.</p>


    <p>The Client is informed that he may in any event have recourse to conventional mediation, to existing sectoral
      mediation bodies or to any alternative dispute resolution method (conciliation, for example) in the event of a
      dispute.</p>


    <p>The Client is also informed that he can also use the Online Dispute Resolution (ODR) platform: https:
      //webgate.ec.europa.eu/odr/main/index.cfm? Event = main.home .show</p>


    <p>All disputes to which the purchase and sale transactions concluded pursuant to these GTCS and which have not been
      the subject of an amicable settlement between the seller or by mediation, will be submitted to the competent
      courts under the conditions of law. common.</p>


    <p>APPENDIX I</p>


    <p>Withdrawal form</p>


    <p>Date ______________________</p>


    <p>This form must be completed and returned only if the Customer wishes to withdraw from the order placed on
      https://practical-go-lessons.com except exclusions or limits to the exercise of the right of withdrawal according
      to the applicable General Conditions of Sale. .</p>


    <p>For the attention of SARL, MAXIMILIEN ANDILE CONSULTING</p>


    <p>233 Rue du Faubourg Saint Honoré, 75001 Paris</p>


    <p>I hereby notify the withdrawal of the contract relating to the property below:</p>


    <p>- Order from (indicate date)</p>


    <p>- Order number: .......................................</p>


    <p>- Client name : ...........................</p>


    <p>- Customer's address: ........................</p>


    <p>Signature of the Client (only if this form is notified on paper)</p>


  </div>
</template>

<script>
export default {
  name: "CGV"
}
</script>

<style scoped>

</style>